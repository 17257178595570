import { render, staticRenderFns } from "./ForgetPass.vue?vue&type=template&id=470e5bd5&scoped=true&"
import script from "./ForgetPass.vue?vue&type=script&lang=js&"
export * from "./ForgetPass.vue?vue&type=script&lang=js&"
import style0 from "./ForgetPass.vue?vue&type=style&index=0&id=470e5bd5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "470e5bd5",
  null
  
)

export default component.exports