<template>
<div class="scope">
    <img src="./../../assets/logo_novelrealm.png" width="80px" />
    <div>ลืมรหัสผ่าน</div>
    <b-alert :variant="showStatus" v-if="showStatus!=false" show>{{statusText}}</b-alert>
    <div class="input">
        <label for="username">ชื่อผู้ใช้งาน</label>
        <b-form-input id="username" v-model="username" @keyup.enter="forget(username)" placeholder="ชื่อผู้ใช้งาน"></b-form-input>
    </div>
    <div class="searching">
        <b-button class="login" @click="forget(username)">
            <b-spinner v-if="loadingForget" small label="Small Spinner" class="mr-2"></b-spinner>ขอรหัสผ่านใหม่
        </b-button>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapActions
} from 'vuex'

export default {
    data() {
        return {
            showForgetModal: false,
            showRegisModal: false,
            loadingForget: false,
            showStatus: false,
            statusText: ''
        }
    },
    methods: {
        ...mapActions(['SET_LOGIN_STATUS', 'SET_USER_INFO']),
        async forget(username) {
            this.loadingForget = true
            const formData = new FormData();
            //console.log(username)
            formData.append('username', username);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/forgetpass", formData)
                    .then(res => {
                        //console.log(res)
                        if (res.data.status == 200) {
                            //console.log(res.data.description)
                            if (res.data.description == 'sent'){
                                this.statusText = 'ระบบได้ทำการส่งจดหมายแล้ว กรุณาตรวจสอบกล่องจดหมายของท่าน'
                                this.showStatus = 'success'
                            }
                            if (res.data.description == 'fb'){
                                this.statusText = 'คุณทำการเข้าสู่ระบบผ่าน Facebook กรุณาเข้าสู่ระบบด้วย Facebook'
                                this.showStatus = 'info'
                            }
                        } else if (res.data.status == 404) {
                            this.statusText = 'ระบบได้ทำการส่งจดหมายแล้ว กรุณาตรวจสอบกล่องจดหมายของท่าน'
                            this.showStatus = 'warning'
                        }
                        this.loadingForget = false
                    })
                    .catch(err => {
                        console.error("login_error : " + err);
                    });
            } catch (err) {
                console.error("login_error : " + err);
            }
        }
    }
}
</script>

<style scoped>
.scope {
    padding: 5% 15% 0.2% 15%;
    text-align: center;
}

label {
    float: left;
    font-size: 12px;
    margin-bottom: 0px;
}

.input {
    margin-top: 15px;
}

.option {
    padding: 0px;
    font-size: 13px;
    margin-top: 8px;
}

/* label.custom-control-label > #text, */
.forgot {
    padding-top: 2px;
}

input {
    border-color: #A8A8A8;
    font-size: 14px;
    border-radius: 6px;
}

.login {
    background: #F5D087;
}

.login:focus,
.login:hover {
    background-color: #FAC762;
    box-shadow: none;
}

.login,
.loginWithFb {
    width: 100%;
    margin-top: 20px;
    border-radius: 6px;
    border: none;
    font-weight: bold;
}

.loginWithFb {
    margin-bottom: 35px;
    background-color: #4267B2;
}

.loginWithFb:focus,
.loginWithFb:hover {
    background-color: rgb(42, 83, 163);
    box-shadow: none;
}

.asking {
    font-size: 13px;
}

.signUp,
.forgot {
    color: #EE806B;
    font-weight: bold;
}

.signUp {
    text-decoration: underline;
}
</style>
